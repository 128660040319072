<template>
	<div
		style="display: flex;align-items: center;justify-content: center;position: absolute;width:100%;height: 100%;overflow: auto;left:0;top:0;">
		<img v-if="info.id" :src="info.qrcode" style="width:100%" @mousedown="press" @mouseleave="cancelLongPress"
			@mouseup="cancelLongPress" @touchstart="press" @touchmove="cancelLongPress" @touchcancel="cancelLongPress"
			@touchend="cancelLongPress" />
	</div>
</template>
<!-- <script src="./alert.js"></script> -->
<script>
	//调试用的移动端 console
	// if (/test=cyf/.test(window.location.href)) {
	// 	var ts = document.createElement('script')
	// 	ts.src = "https://oss.mf.huiwankj.com/cdn/eruda.min.js";
	// 	document.head.append(ts);
	// 	ts.onload = function() {
	// 		window.eruda && window.eruda.init();
	// 	}
	// }
	// import encrypted from './encrypted';
	// console.log(encrypted);
	/* 
		window.getQueryString = (name) => {
			let url = window.location.href
			if (url) {
				var reg = new RegExp('(\\?|&)' + name + '=([^#&]*)(#|&|$)', 'i');
				var r = url.match(reg);
				// console.log(r);
				if (r != null) {
					if (name.toLowerCase() == 'appid') {
						return unescape(r[2]).replace('null', '');
					} else {
						return unescape(r[2]);
					}
				}
			}
			return '';
		}

		window.to404 = () => {
			// error('地址错误');
			// document.head.innerHTML = '<title>404</title>'
			document.body.style.cssText =
				"display: flex;align-items: center;justify-content: center;color:#f00;font-size:50px;height:100vh"
			// document.body.innerHTML = document.title = '404'

			// window.location.hostname && window.location.replace('https://qzone.qq.com/gy/404/')
			// window.location.hostname && window.location.replace('https://qzone.qq.com/gy/404/')

			return '地址错误'
		}

		window.std = (function() {
			let is_test = window.location.host == '' || window.location.hostname == 'localhost',
				d = atob(window.getQueryString('d')).split(',');
			// is_test = false;
			// console.log(d);
			if (!is_test || window.getQueryString('host')) {
				// setTimeout(() => console.warn(document.referrer || 'null', performance.getEntriesByType("navigation")[0].type,
				// 	performance.navigation), 1000)
				var chars = 'abcdefghijklmnopqrstuvwxyz0123456789',
					maxPos = chars.length;
				// http://l3p5d8eprn.qishihk.cn?d=Miww;
				let pwd = window.location.hostname.split('.')[0],
					c = 0,
					l;
				if (pwd.length !== 10) throw window.to404();
				for (var i = 0; i < pwd.length; i++) {
					// console.log(i);
					let n = chars.indexOf(pwd.charAt(i));
					if (i == pwd.length - 1) l = n;
					else c += n;
				}
				let s = Math.abs(c - Number(d[0]));
				if (s % maxPos !== l) throw window.to404();
			}
			return d;
		})()
	 */

	// console.log(window.std)
	// if (window.location.host == '' || window.location.hostname == 'localhost') {
	// 	window.hostName = 'http://${code}.mengtui.store';
	// 	window.apiUrl = 'http://code.mengtui.store';
	// }
	//else {
	// 	// // 混淆加密js----https://tool.chinaz.com/js.aspx
	// 	// var encrypted = document.createElement('script')
	// 	// encrypted.src = "/encrypted.js";
	// 	// document.head.append(encrypted);
	// }


	let liveCode = JSON.parse(localStorage.getItem('liveCode')) || {},
		codeId = JSON.parse(localStorage.getItem('codeId')) || {};

	import ajax from './ajax';
	// import config from './public/config';
	// console.log(config);
	export default {
		data() {
			return {
				baseUrl: 'http://api.qishihk.cn',
				channel: window.std[0],
				time: window.getQueryString('t') ? Math.floor(new Date(window.getQueryString('t')).getTime() / 1000) : 0,
				isTest: /test=cyf/.test(window.location.href),
				info: {
					config: {}
				},
			};
		},
		created() {
			console.warn(this.channel, this.time, window.apiUrl);
			setTimeout(() => {
				console.log(this.channel, this.time, window.apiUrl)
			}, 5000);
			// var config = document.createElement('script')
			// config.src = "/config.js";
			// document.head.append(config);
			// config.onload = () => {
			this.baseUrl = window.apiUrl || 'http://api.qishihk.cn';
			ajax({
				action: this.baseUrl + '/common/getQrCode',
				method: 'get',
				// headers: {
				// 	'content-type': 'application/json; charset=utf-8'
				// },
				data: {
					t: this.time,
					channelCodeId: this.channel,
					subdomain: window.location.hostname,
					id: liveCode[this.channel] || ''
				},
				onSuccess: res => {
					if (res.code == 10000 && res.data) {
						// window.setL
						liveCode[this.channel] = res.data.id;
						localStorage.setItem('liveCode', JSON.stringify(liveCode));
						this.info = res.data
					} else throw window.to404()
				},
				onError: () => {
					throw window.to404()
				}
			});
			// }

		},
		methods: {
			press() {
				// console.log('touch down 500', e);
				this.longPressTimer = setTimeout(() => {
					// console.warn('longPress');
					liveCode[this.channel] && (this.isTest || !codeId[liveCode[this.channel]]) && ajax({
						action: this.baseUrl + '/common/addScanNum',
						method: 'get',
						// headers: {
						// 	'content-type': 'application/json; charset=utf-8'
						// },
						data: {
							channelCodeId: this.channel,
							qrCodeId: this.info.id
						},
						onSuccess: s => {
							console.warn(s);
						}
					});
					// $.get(baseUrl +
					// 			'/common/addScanNum', {
					// 				channelCodeId: this.channel,
					// 				qrCodeId: this.info.id
					// 			}, (res) => {
					// 				if (res.code == 10000) {
					// 					// console.log('第一次',codeId);
					// 					codeId[liveCode[this.channel]] = true;
					// 					localStorage.setItem('codeId', JSON.stringify(codeId));
					// 				}
					// 			})
				}, 500);
			},
			cancelLongPress() {
				// console.log('cancelLongPress', e, 'clearTimeout')
				clearTimeout(this.longPressTimer);
			},
			// toast(msg, time) {
			// 	let doc = document.getElementById('toast') || document.createElement('div');
			// 	doc.id = 'toast';
			// 	if (msg) {
			// 		doc.style.cssText =
			// 			`position: fixed;top: 0%;left: 0%;width:100%;height:100%;z-index:99999;display:flex;align-items: center;justify-content: center;`;
			// 		doc.innerHTML =
			// 			'<span style="background-color: rgba(0, 0, 0, 0.8);color:#fff;font-size:12px;padding:5px 15px;border-radius:9rem;">' +
			// 			msg + '</span>';
			// 		document.body.append(doc);
			// 	}
			// 	clearTimeout(st);
			// 	st = setTimeout(() => {
			// 		doc.style.display = 'none'
			// 	}, time || (msg ? 2000 : 0));
			// },
			// openLink(link) {
			// 	console.warn(link);
			// 	if (link) {
			// 		if (is_test && !confirm('是否测试跳转？')) return console.warn('转链跳转', link);
			// 		window.location.href = link
			// 	}
			// },
		}
	};
</script>

<style>
	body {
		padding: 0;
		margin: 0;
	}

	img {
		max-width: 100%;
	}
</style>
import { render, staticRenderFns } from "./app.vue?vue&type=template&id=07da0235&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"
import style0 from "./app.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!C:\\Users\\Administrator\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports